import React, { useState, useContext, useEffect } from "react";
import { usePost, useFetch } from "hooks";
import UserService from "services/UserService";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { AppContext } from "context/AppContext";
import { useToastContext, ADD } from "context/ToastContext";
import OtpInput from "react-otp-input";
import styled from "styled-components";
import { useAuth } from "context/AuthContext";
import { useHttp } from "hooks";

export const StyledOTP = styled(OtpInput)`
  input {
    width: 3em;
    height: 3em;
    border: 1px solid red;
  }
`;

export default function Login() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [showOTP, setShowOtp] = useState(false);
  const { setUserRole } = useContext(AppContext);
  const { toastDispatch } = useToastContext();
  const { setAuthState, user } = useAuth();

  const { state } = useLocation();
  const {
    error: errorOTP,
    loading: loadingOTP,
    sendHttpRequest: requestOTP,
  } = useHttp();
  const {
    error: errorLogin,
    loading: loginLoading,
    sendHttpRequest: login,
  } = useHttp();
  const {
    error: errorResend,
    loading: loadingResend,
    sendHttpRequest: resend,
  } = useHttp();

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    permission: "",
    country: "",
  });
  const [useLogin, setUseLogin] = useState({
    id: "",
    otp: "",
  });
  const handleOTPChange = (otp) => setUseLogin({ ...useLogin, otp });

  const isBaAccount = (id) => {
    let role = roles.find((el) => el?._id === id);
    if (role?.name?.toLowerCase() === "ba" || role?.name?.toLowerCase() === "mkopa") {
      return true;
    } else {
      return false;
    }
  };

  const requestOneTimePass = async (e) => {
    sessionStorage.clear();
    e.preventDefault();

    let _data = {
      auth: credentials.username,
      password: credentials.password,
      role: credentials.permission,
      country: credentials.country,
    };

    requestOTP("admin_login", "POST", _data, ({ data: { admId }, msg }) => {
      setUseLogin({ ...useLogin, id: admId });
      setShowOtp(true);
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };
  const loginUser = async (e) => {
    sessionStorage.clear();
    e.preventDefault();

    const _data = {
      admId: useLogin.id,
      otp: useLogin.otp,
    };

    login("checkadminotp", "POST", _data, ({ data: { token, user } }) => {
      const { lname, fname, role, id, AssignedDealer, country,partnerId,warehouse } = user;
      let cntry = country?.toLowerCase();
      setUserRole(user);
      window.sessionStorage.setItem(
        "loginData",
        JSON.stringify({
          token,
          role,
          partnerId,
          id,
          lname,
          fname,
          AssignedDealer,
          country: cntry,warehouse
        })
      );

      setAuthState((prevState) => ({
        ...prevState,
        user,
        isAuthenticated: true,
      }));

      setUseLogin({ ...useLogin, otp: "" });
    });
  };

  const loginBA = async (e) => {
    sessionStorage.clear();
    e.preventDefault();

    let _data = {
      auth: credentials.username,
      password: credentials.password,
      role: credentials.permission,
      country: credentials.country,
    };

    requestOTP("admin_login", "POST", _data, ({ data: { token, user } }) => {
      const { lname, fname, role, id, AssignedDealer, country,warehouse } = user;
      let cntry = country?.toLowerCase();
      setUserRole(user);
      window.sessionStorage.setItem(
        "loginData",
        JSON.stringify({
          token,
          role,
          id,
          lname,
          fname,
          AssignedDealer,
          country: cntry,
          warehouse
        })
      );

      setAuthState((prevState) => ({
        ...prevState,
        user,
        isAuthenticated: true,
      }));

      navigate(
        renderDashboard(role?.permission, country?.toLowerCase()) || state?.path
      );
      setUseLogin({ ...useLogin, otp: "" });
    });
  };
  const resendOTPass = async (e) => {
    sessionStorage.clear();
    e.preventDefault();
    setUseLogin({ ...useLogin, otp: "" });

    let _data = {
      admId: useLogin.id,
      password: credentials.password,
    };
    resend("resend_otp", "POST", _data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      setUseLogin({ ...useLogin, otp: "" });
    });
  };

  const { handleData: handleRolesData, loading: loadingRoles } =
    useFetch("get_role");

  handleRolesData((data) => {
    setRoles(data.roles);
  });

  function renderDashboard(param, country) {
    switch (param) {
      // case 12:
      //   return "/shop/dashboard";
      case 18:
        return `/${country}/sales-agent/dashboard`;
      case 17:
        return `/${country}/inventory/inv-rfq-stocks`;
      case 14:
        return `/${country}/agent/mkopa`;
      case 12:
        return `/${country}/repair-pro/agent`;
      case 11:
        return `/${country}/management/dashboard`;
      case 10:
        return `/${country}/accounts/dashboard`;
      case 9:
        return `/${country}/procurement/outbound`;
      case 8:
        return `/${country}/agent/buy-phone`;
      case 7:
        return `/${country}/marketing/dashboard`;
      case 6:
        return `/${country}/quality-check/pre-qc`;
      case 4:
        return `/${country}/rfo/dashboard`;
      case 3:
        return `/${country}/customer-care/dashboard`;
      case 2:
        return ``;

      case 1:
        return `/${country}/admin/dashboard`;
      default:
      // return "/";
    }
  }

  const showPassword = (event) => {
    let element =
      event.target.parentElement.parentElement.querySelector("input");
    if (element.type === "text") {
      element.type = "password";
      event.target.innerText = "Show";
    } else {
      element.type = "text";
      event.target.innerText = "Hide";
    }
  };

  const onChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <>
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="login-card">
                {!showOTP && (
                  <form
                    className="theme-form login-form"
                    method="post"
                    onSubmit={
                      isBaAccount(credentials.permission)
                        ? loginBA
                        : requestOneTimePass
                    }
                  >
                    <h4>Login</h4>
                    <h6>Welcome back! Log in to your account.</h6>

                    <div className="form-group">
                      <label>Select your Country</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="icofont icofont-flag"></i>
                        </span>
                        <select
                          className="form-control"
                          name="country"
                          placeholder="Select a country"
                          onChange={onChange}
                          required
                        >
                          <option value="">Select a country</option>
                          <option value="ke">
                            {" "}
                            {"\uD83C\uDDF0\uD83C\uDDEA"} Kenya
                          </option>
                          <option value="ug">
                            {"\uD83C\uDDFA\uD83C\uDDEC"} Uganda
                          </option>
                          <option value="tz">
                             {"\u{1f1f9}\u{1f1ff}"} Tanzania
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Authentication Role</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="icofont icofont-user"></i>
                        </span>
                        <select
                          className="form-control"
                          name="permission"
                          placeholder="Select a role"
                          onChange={onChange}
                          required
                        >
                          <option value="">Select Role</option>
                          {loadingRoles && (
                            <option disabled>please wait...</option>
                          )}
                          {roles.map((role) => (
                            <option value={role._id} key={role._id}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Email Address</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="icofont icofont-envelope"></i>
                        </span>
                        <input
                          className="form-control"
                          type="email"
                          name="username"
                          placeholder="email@gmail.com"
                          onChange={onChange}
                          required
                          disabled={!credentials.permission}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="icofont icofont-key"></i>
                        </span>
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          placeholder="*********"
                          onChange={onChange}
                          required
                          disabled={!credentials.permission}
                        />
                        <div
                          className="show-hide"
                          onClick={(e) => showPassword(e)}
                        >
                          <span className="show"> Show </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="checkbox">
                        <input id="checkbox1" type="checkbox" />
                        <label htmlFor="checkbox1">
                          <small>Remember password</small>
                        </label>
                      </div>
                    </div>

                    <p className="text-right">
                      <Link className="link " to="/forgot-password">
                        Forgot password?
                      </Link>
                    </p>
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-block ld-ext-right"
                        type="submit"
                        disabled={loadingResend || loginLoading || loadingOTP}
                      >
                        {isBaAccount(credentials.permission)
                          ? "Login"
                          : "Request OTP"}

                        {(loadingResend || loginLoading || loadingOTP) && (
                          <>
                            <div className="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                )}
                {showOTP && !isBaAccount(credentials.permission) && (
                  <form
                    className="theme-form login-form"
                    method="post"
                    onSubmit={loginUser}
                  >
                    <div className="form-group">
                      <div
                        style={{
                          border: 0,
                          cursor: "pointer",
                          width: "fit-content",
                        }}
                        onClick={() => {
                          setShowOtp(false);
                          setUseLogin({ ...useLogin, otp: "" });
                          // setLoading(false);
                        }}
                      >
                        <i className="icofont icofont-long-arrow-left " /> Back
                      </div>
                    </div>
                    <div className="form-group">
                      <label> Enter OTP</label>

                      <StyledOTP
                        value={useLogin.otp}
                        onChange={handleOTPChange}
                        numInputs={4}
                        separator={<span>-</span>}
                        placeholder=""
                        inputStyle={{
                          width: "3em",
                          height: "3em",
                          border: "1px solid #24695c",
                          margin: "20px 4px",
                        }}
                        isInputNum={true}
                        containerStyle={{
                          justifyContent: "center",
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <small>
                          Didn't receive OTP,{" "}
                          <a
                            href
                            className="text-primary"
                            onClick={(e) => resendOTPass(e)}
                            style={{ cursor: "pointer" }}
                          >
                            <u>Resend</u>
                          </a>
                        </small>
                      </label>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-block ld-ext-right"
                        type="submit"
                        // disabled={loading}
                      >
                        Log In
                        {(loadingResend || loginLoading || loadingOTP) && (
                          <>
                            <div className="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
