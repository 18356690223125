import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "context/AppContext";
import { AuthContext } from "context/AuthContext";

import Modal from "components/elements/modal/Modal";

export default function LogoutUser(props) {
  const navigate = useNavigate();

  const { logout } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const onFinish = () => {
    logout();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => onFinish()}
      onReject={props.close}
      title="Leaving so soon?"
      form="logoutUserForm"
      loading={loading}
    >
      Are you sure you want to logout?
    </Modal>
  );
}
